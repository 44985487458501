<style></style>

<section id="moto">
  <div class="content_gap"></div>
  <div class="container">
    <div class="flex align_start" data-aos="fade-up">
      <div class="col_5">
        <div class="col_container">
          <div class="moto_box">
            <div class="section_tag">
              <h5>our moto</h5>
            </div>
            <div class="section_heading">
              <h2>Grow with RecruitTech community.</h2>
            </div>
          </div>
        </div>
      </div>

      <div class="col_7">
        <div class="moto_box">
          <div class="section_text">
            <p>
              Magna tellus purus arcu adipiscing diam quisque arcu adipiscing
              turpis augue. At. Purus arcu adipiscing diam magna tellus.
            </p>
          </div>
          <a href="#about" class="smooth cmn_btn large">about us</a>
        </div>
      </div>
    </div>
  </div>
</section>
