<script lang="ts">
import Header from "./components/Header.svelte";
import HomeBanner from "./components/HomeBanner.svelte";
import Moto from "./components/Moto.svelte";
import About from "./components/About.svelte";
import Services from "./components/Services.svelte";
import Footer from "./components/Footer.svelte";
</script>

<body>
  <Header />
  <HomeBanner />
  <Moto />
  <About />
  <Services />
  <Footer />
  <div class="copyright_info">
    <div class="container">
      <p>
        © 2021 RecruitTech | Designed by
        <a href="https://www.manksdev.com">ManksDev</a>
      </p>
    </div>
  </div>
</body>
