<style>
section {
  position: relative;
  margin-top: 50px;
  overflow: hidden;
}

@media (max-width: 1399px) and (min-width: 1250px) {
  /* Header */
  section {
    margin-top: 40px;
  }
}

@media (max-width: 1249px) and (min-width: 1025px) {
  section {
    margin-top: 40px;
  }
}

@media (max-width: 1024px) and (min-width: 768px) {
  section {
    margin-top: 60px;
  }

  section .flex {
    flex-direction: column;
  }

  section .col_6 {
    width: auto;
  }
}

@media (max-width: 767px) and (min-width: 260px) {
  section {
    margin-top: 60px;
  }

  section .flex {
    flex-direction: column;
  }
}
</style>

<section>
  <div class="container">
    <div class="flex align_start">
      <div class="col_6" data-aos="fade-left" data-aos-delay="100">
        <div class="col_container">
          <div class="banner_flex">
            <div class="banner_heading">
              <h1>
                Interviewed between more then <span>50,000</span> tech enthusiast
                worldwide.
              </h1>
            </div>
            <div class="banner_text">
              <p>
                Magna tellus purus arcu adipiscing diam quisque arcu adipiscing
                turpis augue. At.
              </p>
            </div>
            <div class="banner_btns">
              <a href="#contact" class="smooth cmn_btn small">get started</a>
              <button class="cmn_small_white">
                <img src="images/play.svg" alt="How we work" />
                How we work
              </button>
            </div>
            <div class="banner_clients">
              <img src="images/microsoft.png" alt="Microsoft" />
              <img src="images/airbnb.png" alt="Air BNB" />
              <img src="images/bissel.png" alt="Bissell" />
              <img src="images/godaddy.png" alt="Go Daddy" />
            </div>
          </div>
        </div>
      </div>

      <div class="col_6">
        <div class="col_container">
          <div class="banner_gallery">
            <div class="banner_gallery_items">
              <div class="single_gallery">
                <img
                  data-aos="fade-up"
                  data-aos-delay="200"
                  src="images/gallery_1.png"
                  alt="" />
              </div>
              <div class="single_gallery">
                <img
                  data-aos="fade-up"
                  data-aos-delay="500"
                  src="images/gallery_3.png"
                  alt="" />
              </div>
              <div class="single_gallery">
                <img
                  data-aos="fade-up"
                  data-aos-delay="900"
                  src="images/gallery_5.png"
                  alt="" />
              </div>
              <div class="single_gallery">
                <img
                  data-aos="fade-up"
                  data-aos-delay="350"
                  src="images/gallery_2.png"
                  alt="" />
              </div>
              <div class="single_gallery">
                <img
                  data-aos="fade-up"
                  data-aos-delay="750"
                  src="images/gallery_4.png"
                  alt="" />
              </div>
              <div class="single_gallery">
                <img
                  data-aos="fade-up"
                  data-aos-delay="1000"
                  src="images/gallery_6.png"
                  alt="" />
              </div>
            </div>
          </div>
        </div>
        <div class="home_bg">
          <img src="images/bg.svg" alt="bg" />
        </div>
      </div>
    </div>
  </div>
</section>
