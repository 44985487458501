<section id="services">
  <div class="content_gap"></div>
  <div class="container">
    <div class="service_heading">
      <div class="section_tag">
        <h5>our services</h5>
      </div>
      <div class="section_heading">
        <h2>How we make things easy for you?</h2>
      </div>
    </div>

    <!-- Service 001 -->
    <div class="service_single">
      <div class="flex">
        <div class="col_6" data-aos="fade-right">
          <div class="col_container">
            <div class="cmn_images">
              <img src="./images/at_first.svg" alt="At first" class="hide" />
            </div>
          </div>
        </div>
        <div class="col_6" data-aos="fade-left">
          <div class="col_container">
            <div class="service_items">
              <div class="moto_box">
                <div class="section_tag">
                  <img src="./images/suitcase.svg" alt="" />
                  <h5>AT FIRST WE</h5>
                </div>
                <div class="section_heading">
                  <h2>
                    Interview with the hiring company to understand their
                    needs/requirement and define the job opening properly.
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Service 002 -->
    <div class="service_single">
      <div class="flex">
        <div class="col_6" data-aos="fade-left">
          <div class="col_container">
            <div class="moto_box">
              <div class="section_tag">
                <img src="./images/suitcase.svg" alt="" />
                <h5>secondly we</h5>
              </div>
              <div class="section_heading">
                <h2>
                  Assess market candidates to find the ones that fit their
                  criteria.
                </h2>
              </div>
            </div>
          </div>
        </div>
        <div class="col_6" data-aos="fade-right">
          <div class="col_container">
            <div class="service_items">
              <img src="./images/second.svg" alt="Secondly" class="hide" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Service 003 -->
    <div class="service_single">
      <div class="flex">
        <div class="col_6" data-aos="fade-right">
          <div class="col_container">
            <div class="cmn_images">
              <img src="./images/last.svg" alt="At last" class="hide" />
            </div>
          </div>
        </div>
        <div class="col_6" data-aos="fade-left">
          <div class="col_container">
            <div class="service_items">
              <div class="moto_box">
                <div class="section_tag">
                  <img src="./images/suitcase.svg" alt="" />
                  <h5>last of all</h5>
                </div>
                <div class="section_heading">
                  <h2>
                    Hiring company can choose to invite the shortlisted
                    candidates for their own interviews.
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
