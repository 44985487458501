<footer id="contact">
  <div class="container">
    <div class="flex">
      <div class="col_7">
        <div class="col_container">
          <div class="footer_heading">
            <h1>Contact Us</h1>
          </div>
          <div class="footer_details">
            <div class="footer_item">
              <img src="./images/address.svg" alt="Address" />
              <p>
                Akshya Nagar 1st Block 1st Cross, Rammurthy nagar,
                Bangalore-560016
              </p>
            </div>
            <div class="footer_item">
              <img src="./images/call.svg" alt="Call here" />
              <p>+(880) 163 312 4641</p>
            </div>
            <div class="footer_item">
              <img src="./images/email.svg" alt="Email here" />
              <p>hello@manksdev.com</p>
            </div>
            <div class="footer_item">
              <img src="./images/clock.svg" alt="Time" />
              <p>10:00 - 23:00</p>
            </div>
          </div>
        </div>
      </div>

      <div class="col_5">
        <div class="col_container">
          <div class="form_box">
            <div class="contact_form">
              <div class="form_heading">
                <h3>Drop us a message!</h3>
              </div>

              <form
                action="https://docs.google.com/forms/u/0/d/e/1FAIpQLSfyFX3sentnOv7GNfBeTa3iaP5eJwOZSfUeV93zzqrT7xdqgg/formResponse"
                method="POST">
                <div class="form_element">
                  <span>Your name</span>
                  <input
                    type="text"
                    name="entry.1532294032"
                    placeholder="Name" />
                </div>

                <div class="form_element">
                  <span>Your email</span>
                  <input
                    type="email"
                    name="entry.1574498645"
                    placeholder="Email" />
                </div>

                <div class="form_element">
                  <span>Write enquiry</span>
                  <!-- <input type="text" name="entry.288758405" placeholder="Enquiry"> -->
                  <textarea name="entry.288758405" placeholder="Enquiry"
                  ></textarea>
                </div>

                <button class="submit" type="submit">submit</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>
