<section id="about">
  <div class="content_gap"></div>
  <div class="container">
    <div class="flex">
      <div class="col_6" data-aos="fade-right">
        <div class="col_container">
          <div class="cmn_images">
            <img src="./images/about.svg" alt="About Us" />
          </div>
        </div>
      </div>

      <div class="col_6" data-aos="fade-left">
        <div class="moto_box">
          <div class="col_container">
            <div class="section_tag">
              <h5>about us</h5>
            </div>
            <div class="section_heading">
              <h2>Non facilisis sit lacus arcu feugiat. Nulla.</h2>
            </div>
            <div class="section_text">
              <p>
                Elementum eget egestas consectetur molestie. At suspendisse dui
                mi vitae feugiat. Sit hac tincidunt rhoncus consectetur.
                Fermentum tortor mauris scelerisque vel. Mauris diam odio
                egestas in libero pellentesque aliquam interdum. Amet morbi
                cursus ullamcorper mauris mattis ullamcorper integer nibh odio.
                Quis dui porttitor ipsum et. Auctor praesent vitae purus mauris
                purus massa phasellus. Leo facilisi vulputate pellentesque nec
                faucibus. Vulputate donec lectus neque, eget tristique
                tristique. Velit sagittis tristique amet neque non mauris,
                pellentesque. Quisque non mi tincidunt mauris. Volutpat,
                ridiculus ipsum nec vestibulum id. Nisi.
              </p>
            </div>
            <div class="about_clients">
              <img src="./images/cma.png" alt="CMA" />
              <img src="./images/expertise.png" alt="Expertise" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
