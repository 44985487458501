<style>
.noSelect {
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
}
</style>

<script>
</script>

<header>
  <div class="container">
    <nav>
      <div class="logo">
        <img src="images/logo.svg" alt="Recruit Tech" />
      </div>

      <div class="menu">
        <ul>
          <li><a href="#about" class="smooth">About</a></li>
          <li><a href="#services" class="smooth">Services</a></li>
          <li><a href="#contact" class="smooth">Contact</a></li>
          <li>
            <a href="#contact" class="smooth cmn_btn small sml_font">
              <span>get started</span>
            </a>
          </li>
        </ul>
      </div>

      <div class="burger">
        <div class="line1"></div>
        <div class="line2"></div>
      </div>
    </nav>
  </div>
</header>
